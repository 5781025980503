@import 'variables';

.list-header {
  padding: 0 @tile-padding;
}

.empty-state {
  color: @gray-light;
  padding: @padding-large-vertical @padding-large-horizontal;
  font-size: @font-size-h6;

  .lead {
    font-size: @font-size-h5;
    font-weight: normal;
  }
  html.ios & {
    font: -apple-system-body;
  }
}

.tile .empty-state {
  color: rgba(255, 255, 255, .8);

  .lead {
    color: #fff;
  }
}
