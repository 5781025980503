@import '@universityofwarwick/id7/less/variables.less';
@import '@universityofwarwick/id7/less/id7-no-fa.less';
@import '@universityofwarwick/id7/less/font-awesome-pro';
@import '@fortawesome/fontawesome-pro/less/v4-shims';
@import 'eonasdan-bootstrap-datetimepicker/src/less/_bootstrap-datetimepicker.less';
@import (less) 'tablesort/tablesort.css';

.apply-brand(@id7-brand-default);

@id7-image-root-dir: '../lib/id7/images';
@icon-font-path: '../lib/fontawesome-pro';
@fa-font-path: '../lib/fontawesome-pro';

@import 'variables';
@import 'activity-item';
@import 'app-icon';
@import 'news-item';
@import 'top-page-notice';
@import 'notifications';
@import 'utilities';
@import 'flexi-picker';

.id7-utility-masthead {
  background-color: rgb(91, 48, 105);
}
.apply-left-border-image('/assets/images/border-left.jpg');
.apply-right-border-image('/assets/images/border-left.jpg');

dd.error {
  color: @id7-brand-red;
}

hr.tight {
    margin: 0.75em 0 0;
}

th[role=columnheader]:not(.no-sort):after {
  margin-left: 7px;
  margin-top: 12px;
}

.count-column {
  width: 10em;
  text-align: right;
}

td.count-column {
  font-family: Menlo, "Lucida Sans Typewriter", "Lucida Console", monaco, "Bitstream Vera Sans Mono", monospace;
  font-weight: bold;
}

.split-form__sections {
  position: relative;

  > section {
    display: none;
    width: 100%;
    position: absolute;

    &.active {
      position: relative;
    }
  }
}

.split-form--transitioning .split-form__sections {
  overflow: hidden;
}

.split-form__sections > section.active {
  display: block;
}

.confirm-delete {
  display: none;
  opacity: 0;
  left: 20%;
}

.news-item, .activity-item {
  .btn-toolbar {
    position: relative;
    form {
      display: inline;
    }
    .btn {
      margin-bottom: 4px;
    }
  }
}

.audience-picker {

  .radio .checkbox,
  .checkbox > .radio,
  .radio .radio,
  .radio .dept-select {
    margin-left: 1.4em
  }

  input.input-search {
    min-width: 50%;
  }
  .list-item {
    padding: 6px 0;
    border-bottom: 1px solid #A8A9AF;
  }
  .list-item__remove {
    margin-left: 6px;
  }
  .error-msg {
    margin: 0 8px;
    color: red;
    display: inline-block;
  }
  .checkbox--list-item-option {
    display: inline-block;
    margin-left: 12px;
  }
}

.activity-item__audience {
  & > div:first-child {
    text-align: right;
  }
  &:not([data-sent=true]) [class^="activity-item__messages-"] {
    display: none;
  }
}

.audience-indicator {
  ul.audience-component-list {
    color: #999;
    margin: 2rem 0;
  }

  .media.hint {
    font-size: 80%;
    color: #999;
    line-height: 1.4;
  }
}
