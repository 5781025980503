.flexi-picker-result {
  span.type {
    font-size: 75%;
    opacity: 0.5;
    padding: 0.5em;
  }

  .description,
  .department,
  .no-groups {
    font-size: 75%;
    opacity: 0.8;
    padding-left: 0;
    margin-top: -4px;
  }

  i {
    margin-left: -17px;
    margin-right: 3px;
  }

  a {
    text-decoration: none;
    transition: none;
  }
}

.rich-result-field {
  color: green !important;
  position: relative;
  vertical-align: middle;

  .val {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 13px;
    white-space: nowrap;
  }

  a.clear-field {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 5px 0 0;
    color: #666;
    font-size: 150%;
    text-decoration: none;
    display: block;
    width: 20px;
  }
}