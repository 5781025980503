.news-item {
  .panel;
  .break-word;

  line-height: @line-height-tile;

  &__body {
    .panel-body;
  }

  &__title {
    .h4;
    border: 0;
    margin-bottom: 0;
  }

  &__image {
    margin: (@line-height-tile-computed / 2) 0;
  }

  &__link {
    margin-bottom: (@line-height-tile-computed / 2);
  }

  &__footer {
    > p, > .news-item__category-tags {
      .small;
    }
    .text-muted;
    margin-top: @line-height-tile-computed;
  }

  &__category-tags {
    margin-bottom: -4px;

    .badge {
      margin-right: 4px;
      margin-bottom: 4px;
      background-color: #E4E7EB;
      font-weight: normal;
      color: #1F2933;
    }
  }
}

.news-categories {
  margin-bottom: @form-group-margin-bottom;

  label {
    display: block;
  }
}

.tile__body .news-item {
  background: none;
  border: none;
  box-shadow: none;

  &__title {
    color: #fff;
  }

  &__footer {
    color: @gray-lighter;
  }

  &__category-tags .badge {
    color: @text-color;
    background-color: #fff;
  }
}

.inset-alert {
  margin: 10px;
}
