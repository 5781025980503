.activity-item {
  .list-group-item;

  line-height: @line-height-tile;

  &, &__title, &__text {
    color: @text-color;
  }

  &__link-indicator {
    padding-left: .4em;
    font-size: .8em;
    color: #007aff;
  }

  &__title {
    html.ios & {
      font: -apple-system-subheadline;
      font-weight: bold;
    }
    font-weight: bold;
  }

  &__text {
    html.ios & {
      font: -apple-system-subheadline;
      font-weight: normal;
    }
    font-weight: normal;
  }

  &__date, &__audience, &__visible-link {
    .small;
    .text-muted;
    html.ios & {
      font: -apple-system-subheadline;
    }
  }

  &--with-url {
    cursor: pointer;
  }

  &--unread {
    background: @alert-info-bg;
  }

  a, a:hover {
    text-decoration: none;
  }

  .muting {
    float: right;
    color: black;
    opacity: 0.4;
    margin-right: -15px;
    margin-top: -10px;
    padding: 10px 15px;
    &:hover {
      opacity: 0.6;
    }
    cursor: pointer;
  }
}

// For elements that want to have the same padding as an item,
// without actually looking like one.
.activity-item-padding {
  padding: 10px 15px;
}

.tile__body .activity-item {
  background: none;
  border: none;
  box-shadow: none;

  &, &__title, &__text {
    color: #fff;
  }

  border-top: 1px solid rgba(255, 255, 255, .4);
  padding: .5em 0;

  &__date {
    color: @gray-lighter;
  }

  &--unread {
    background: rgba(255, 255, 255, .2);
  }

  .app-icon {
    background-color: transparent !important;
    color: #fff !important;
    border: 1px solid rgba(255, 255, 255, .6);
  }
}

