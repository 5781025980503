.top-page-notice {
  background-color: #fc6;
  margin-bottom: 0;
  padding: .3em;
  text-align: center;
  &.masquerade {
    height: @top-page-notice-height;
    overflow: hidden;
  }
}
